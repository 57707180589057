import { usePreferredDark, useFavicon } from '@vueuse/core';
const setFavicon = () => {
    const isDark = usePreferredDark()
    const favicon = computed(() => isDark.value ? '/favicon_dark.png': '/favicon_light.png')
    const faviconApple = computed(() => isDark.value ? '/favicon_apple_dark.png': '/favicon_apple_light.png')
    
  useFavicon(favicon, {
      rel: 'icon',
    })

  useFavicon(faviconApple, {
      rel: 'apple-touch-icon',
      
    })
  }

  onMounted(() => {
    setFavicon()
  })

  export default setFavicon;